import React from 'react'

export const Loader = () => {
    return (
        <div className="w-screen h-screen flex items-center">
            <div className="feeder mx-[auto]">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}
