import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Loader } from "./pages/Loader";
import NotFound from "./pages/NotFound";
const LazyHome = React.lazy(() => import("./pages/Home"));
const LazyFAQ = React.lazy(() => import("./pages/FAQPAGE"));
const LazyDownloads = React.lazy(() => import("./pages/Downloads"));
const LazyUseCase = React.lazy(() => import("./pages/UseCases"));
const LazyPrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const LazyDeleteAccount = React.lazy(() => import("./pages/DeleteAccount"));
const LazyZoom = React.lazy(() => import("./pages/Zoom"));

const ProjectRoutes = () => {
  const allRoutes = [
    {
      path: "/",
      isLazy: true,
      for: <LazyHome />,
    },
    {
      path: "/faq",
      isLazy: true,
      for: <LazyFAQ />,
    },
    {
      path: "/download",
      isLazy: true,
      for: <LazyDownloads />,
    },
    {
      path: "/usecases-all",
      isLazy: true,
      for: <LazyUseCase />,
    },
    {
      path: "/privacy-policy",
      isLazy: true,
      for: <LazyPrivacyPolicy />,
    },
    {
      path: "/delete-account",
      isLazy: true,
      for: <LazyDeleteAccount />,
    },
    {
      path: "/privacypolicy",
      isLazy: true,
      for: <LazyPrivacyPolicy />,
    },
    {
      path: "/zoom",
      isLazy: true,
      for: <LazyZoom />,
    },
    {
      path: "*",
      isLazy: false,
      for: <NotFound />,
    },
  ];

  return (
    <Router>
      <Routes>
        {allRoutes.map((value: any, index: number) => {
          return value.isLazy ? (
            <Route
              key={index}
              path={value.path}
              element={
                <React.Suspense fallback={<Loader />}>
                  {value.for}
                </React.Suspense>
              }
            />
          ) : (
            <Route key={index} path={value.path} element={value.for} />
          );
        })}
      </Routes>
    </Router>
  );
};

export default ProjectRoutes;
