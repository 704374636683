import React from "react";
import Routes from "./Routes";

import TagManager from "react-gtm-module";
const tagManagerArgs = {
  gtmId: "GTM-N6K5475",
};
TagManager.initialize(tagManagerArgs);

function App() {
  window.dataLayer.push({
    event: "pageview",
  });

  return <Routes />;
}

export default App;
