import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "antd/dist/antd-with-locales"
import "./styles/color.css";
import "./styles/font.css";
import "./styles/index.css";
import "./styles/tailwind.css";
import "react-swipeable";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
