import React, { FC } from "react";
import { Column, Row, Img, Text } from "../..";

interface Props {}
const SectionThree: FC<Props> = (props) => {
  return (
    <>
      <Row className="grid grid-cols-1 lg:grid-cols-3 w-[100%] mt-10 lg:mt-0">
        <Column className="lg:col-span-1 bg-white">
          <div className="lg:my-auto mx-auto mt-9 text-center lg:text-left lg:px-[10%]">
            <Text
              className="text-bluegray_900 w-[auto] text-4xl 3xl:text-[55px] xl:text-[40px] font-bold font-almarai"
              as="h2"
              variant="h2"
            >
              Onboard your team
            </Text>

            <div className="flex gap-1 pt-5 flex-wrap">
              <a href="https://apps.apple.com/my/app/quadruple-impact/id1364231993">
                <Img src="..\images\btn_apple.png" className="w-40" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.nibmeetings.android">
                <Img src="..\images\btn_google.png" className="w-40" />
              </a>
              <a href="https://web.quadrupleimpact.com/">
                <Img src="..\images\btn_web.png" className="w-40" />
              </a>
            </div>
          </div>
        </Column>
        <Column className="lg:col-span-2">
          <Img
            src="..\images\usecases\Frame_5.png"
            className="w-[100%] object-contain"
            alt="imageTen Two"
          />
        </Column>
      </Row>
    </>
  );
};

export default SectionThree;
