import React, { FC } from "react";
import { Column, Header, Footer } from "../../components/index";

interface Props {
  children?: any;
  className?: string;
}

export const Layout: FC<Props> = (props) => {
  return (
    <>
      <div className="h-[100vh] flex flex-col">
        <div className="fixed w-full z-[9999]">
          <Header />
        </div>
        <div className="pt-20">
          <Column
            className={
              "bg-white_A700 items-center justify-start mx-[auto] p-[8px]  w-[100%] lg:w-[100%] lg:px-36  " +
              props?.className
            }
          >
            {props.children}
          </Column>
        </div>
        <Footer className="font-almarai w-[100%] mt-auto" />
      </div>
    </>
  );
};
